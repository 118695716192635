// SCSS Color Variables
// Named with this: http://chir.ag/projects/name-that-color

$c-black: #000000;
$c-white: #FFFFFF; 

$c-anakiwa: #8DD8FF;
$c-atoll: #0E537E;
$c-azure: #316DB4;
$c-denim: #157DCE;
$c-holly: #001209;
$c-malibu: #6BD8FA; 
$c-new-york-pink: #DB8383;
$c-picton-blue: #2C97EA;
$c-rhino: #313A62;
$c-sundown: #FFB4B4;
$c-tory-blue: #1061A0;