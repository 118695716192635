@use "../../../../assets/sass/abstract/_responsive.scss" as rwd;

//body global styles - nadpisanie styli bootstrap forms uzytego tylko w akademickim
body {
  @include rwd.breakpoint(tabletmobile) {
    margin-top: 56px;
  }
}

// Consts
$services-details-color: $c-anakiwa;

.servicesPromoBox {
  background-color: $c-azure;

  .rightSide {

    &__content .lnkBtn {
      border-color: $services-details-color;
    }
  }

  .socialMedia__lnk .icon {
    color: $c-white;
  }

  .splide.arrowsLight .splide__arrow:before {
    color: $services-details-color;
  }
}

// Sticky logo
.mainHeader.sticky .stickyLogo img {
  height: 24px;
}

.mainHeader.sticky .stickyLogo img {
  height: 40px;
}

.article__entry {
  .articleAcadem__list {
    li {
      margin-bottom: 24px;
    }

    p {
      margin: 8px 0;
    }
  }
}

.backToHomepage__box {
  justify-content: flex-start;
}

.showSocialPost.socialPost__cover button[data-social-post-button]::before{
  top: 10px;
}