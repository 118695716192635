// Main
$body-color: $c-holly;
$main-font: "Fira Sans";

:root {
  --main-color: #{$c-denim};
  --main-color-darken: #{$c-tory-blue};
  --main-color-light: #{$c-picton-blue};
  --details-color: #{$c-denim};
  --promo-box-slider-bg: #{$c-atoll};
  --promo-box-slider-sign-color: #{$c-malibu};
  --box-data-bg: #{$c-atoll};
}
