/*  */
/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */

/* USAGE MEDIA QUERIES
  div {
    height: 390px;

    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }
  }
*/
/* Copyright 2013-2015 etc Chris Tabor. See https://github.com/christabor/css-progress-wizard/blob/master/LICENSE for details. */
.flexer, .progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.no-flexer, .progress-indicator.stacked {
  display: block; }

.no-flexer-element {
  -ms-flex: 0;
  -webkit-flex: 0;
  -moz-flex: 0;
  flex: 0; }

.flexer-element, .progress-indicator > li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1; }

.progress-indicator {
  margin: 0;
  padding: 0;
  font-size: 80%;
  text-transform: uppercase;
  margin-bottom: 1em; }
  .progress-indicator > li {
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: #bbb;
    display: block; }
    .progress-indicator > li:hover {
      color: #6f6f6f; }
  .progress-indicator > li .bubble {
    border-radius: 1000px;
    width: 20px;
    height: 20px;
    background-color: #bbb;
    display: block;
    margin: 0 auto 0.5em auto;
    border-bottom: 1px solid #888888; }
  .progress-indicator > li .bubble:before,
  .progress-indicator > li .bubble:after {
    display: block;
    position: absolute;
    top: 9px;
    width: 100%;
    height: 3px;
    content: '';
    background-color: #bbb; }
  .progress-indicator > li .bubble:before {
    left: 0; }
  .progress-indicator > li .bubble:after {
    right: 0; }
  .progress-indicator > li.completed {
    color: #65d074; }
    .progress-indicator > li.completed .bubble {
      background-color: #65d074;
      color: #65d074;
      border-color: #247830; }
      .progress-indicator > li.completed .bubble:before, .progress-indicator > li.completed .bubble:after {
        background-color: #65d074;
        border-color: #247830; }
  .progress-indicator > li.active {
    color: #337AB7; }
    .progress-indicator > li.active .bubble {
      background-color: #337AB7;
      color: #337AB7;
      border-color: #122a3f; }
      .progress-indicator > li.active .bubble:before, .progress-indicator > li.active .bubble:after {
        background-color: #337AB7;
        border-color: #122a3f; }
  .progress-indicator > li a:hover .bubble {
    background-color: #5671d0;
    color: #5671d0;
    border-color: #1f306e; }
    .progress-indicator > li a:hover .bubble:before, .progress-indicator > li a:hover .bubble:after {
      background-color: #5671d0;
      border-color: #1f306e; }
  .progress-indicator > li.danger .bubble {
    background-color: #d3140f;
    color: #d3140f;
    border-color: #440605; }
    .progress-indicator > li.danger .bubble:before, .progress-indicator > li.danger .bubble:after {
      background-color: #d3140f;
      border-color: #440605; }
  .progress-indicator > li.warning .bubble {
    background-color: #edb10a;
    color: #edb10a;
    border-color: #5a4304; }
    .progress-indicator > li.warning .bubble:before, .progress-indicator > li.warning .bubble:after {
      background-color: #edb10a;
      border-color: #5a4304; }
  .progress-indicator > li.info .bubble {
    background-color: #5b32d6;
    color: #5b32d6;
    border-color: #25135d; }
    .progress-indicator > li.info .bubble:before, .progress-indicator > li.info .bubble:after {
      background-color: #5b32d6;
      border-color: #25135d; }
  .progress-indicator.stacked > li {
    text-indent: -10px;
    text-align: center;
    display: block; }
  .progress-indicator.stacked > li .bubble:before,
  .progress-indicator.stacked > li .bubble:after {
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%; }
  .progress-indicator.stacked .stacked-text {
    position: relative;
    z-index: 10;
    top: 0;
    margin-left: 60% !important;
    width: 45% !important;
    display: inline-block;
    text-align: left;
    line-height: 1.2em; }
  .progress-indicator.stacked > li a {
    border: none; }
  .progress-indicator.stacked.nocenter > li .bubble {
    margin-left: 0;
    margin-right: 0; }
  .progress-indicator.stacked.nocenter > li .bubble:before,
  .progress-indicator.stacked.nocenter > li .bubble:after {
    left: 10px; }
  .progress-indicator.stacked.nocenter .stacked-text {
    width: auto !important;
    display: block;
    margin-left: 40px !important; }

@media handheld, screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 60%; } }
h1.txtSiteTitle span.label {
  background-color: #48214a;
  font-weight: normal;
  font-size: 18px;
  font-size: 1.28571rem; }

.boxAlert .glyphicon {
  font-size: 30px;
  vertical-align: middle;
  margin-right: 15px;
  float: left; }

.formWCA fieldset {
  display: block;
  background-color: #f1f1f0;
  padding: 70px 25px 25px 25px;
  margin-bottom: 25px;
  position: relative;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px; }
  .formWCA fieldset legend {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 15px 25px 5px 25px;
    margin: 0;
    background-color: #f1f1f0;
    border-color: #bbb;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .formWCA fieldset p {
    margin-bottom: 15px; }
.formWCA textarea {
  -webkit-resize: vertical;
  -khtml-resize: vertical;
  -moz-resize: vertical;
  -ms-resize: vertical;
  -o-resize: vertical;
  resize: vertical; }
  .formWCA textarea.textareaLarge {
    height: 200px; }
.formWCA .txtLabel {
  font-weight: 700;
  display: block;
  margin-bottom: 5px; }
.formWCA .boxRadio {
  display: block;
  margin-bottom: 5px; }
  .formWCA .boxRadio input {
    vertical-align: sub;
    margin-right: 10px; }
.formWCA .help-block {
  font-size: 10px;
  font-size: 0.71429rem;
  color: #999;
  padding-left: 14px; }
.formWCA .form-group {
  position: relative; }
  .formWCA .form-group .help-block.form-error {
    text-align: center;
    font-size: 12px; }
  .formWCA .form-group .help.help-block {
    text-align: center;
    font-size: 12px;
    color: #48214a; }
  .formWCA .form-group.has-success label:after {
    font-family: 'Glyphicons Halflings';
    content: "\e013";
    color: #5cb85c;
    font-size: 12px;
    margin: 0 5px; }
  .formWCA .form-group.has-error label {
    color: #a94442; }
    .formWCA .form-group.has-error label:after {
      font-family: 'Glyphicons Halflings';
      content: "\e014";
      color: #a94442;
      font-size: 12px;
      margin: 0 5px; }
  .formWCA .form-group.row {
    margin-bottom: 0; }
    .formWCA .form-group.row > [class*='col-'] {
      margin-bottom: 10px; }
  .formWCA .form-group span.glyphicon {
    display: inline-block;
    margin: 0 15px;
    color: #ff113d; }
  .formWCA .form-group input[type="file"] {
    display: inline-block; }
  .formWCA .form-group label {
    display: block; }
.formWCA .form-control.valid {
  border-color: #5cb85c; }
.formWCA .boxSubmit {
  text-align: center; }
  .formWCA .boxSubmit button {
    background-color: #48214a;
    font-family: "montserratbold";
    color: #fff;
    font-size: 20px; }
.formWCA .tblEtapy {
  width: 100%; }
  .formWCA .tblEtapy.onError {
    background-color: #f2dede; }
  .formWCA .tblEtapy thead th {
    text-align: center; }
  .formWCA .tblEtapy tbody td {
    padding: 5px 8px; }
    .formWCA .tblEtapy tbody td:first-child {
      width: 100px; }
    .formWCA .tblEtapy tbody td a.lnkTblEtapyRemove {
      color: red; }
  .formWCA .tblEtapy tbody input,
  .formWCA .tblEtapy tbody select {
    width: 100%; }
  .formWCA .tblEtapy tbody select {
    padding: 4px 0; }
.formWCA .lnkAddStage {
  font-size: 12px;
  font-size: 0.85714rem;
  float: right;
  margin: 5px 7px 20px 0; }

.boxRodoDesc .txtRodoDesc {
  font-size: 12px; }
@media (max-width: 767px) {
  .boxRodoDesc .txtRodoDesc {
    font-size: 10px; } }
.boxRodoDesc .tblRodoDesc {
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
  border: 2px solid #ccc; }
@media (max-width: 767px) {
  .boxRodoDesc .tblRodoDesc {
    font-size: 10px; } }
.boxRodoDesc .tblRodoDesc a {
  display: inline-block;
  border-bottom: 1px dotted #888; }
.boxRodoDesc .tblRodoDesc a:hover {
  text-decoration: none;
  color: #000;
  border-bottom-style: solid; }
.boxRodoDesc .tblRodoDesc thead {
  background-color: #ccc; }
.boxRodoDesc .tblRodoDesc thead p {
  margin: 5px;
  text-align: center;
  font-weight: 700; }
.boxRodoDesc .tblRodoDesc tbody tr {
  border-bottom: 2px solid #ccc; }
.boxRodoDesc .tblRodoDesc tbody tr:last-child {
  border: 0; }
.boxRodoDesc .tblRodoDesc tbody th, .boxRodoDesc .tblRodoDesc tbody td {
  padding: 5px; }
.boxRodoDesc .tblRodoDesc tbody th {
  font-weight: 700;
  border-right: 2px solid #ccc;
  text-align: center; }
.boxRodoDesc .tblRodoDesc tbody td ul {
  padding-left: 20px;
  margin: 0; }

/*# sourceMappingURL=style-forms.css.map */
