.txtSiteTitle {
  line-height: normal;
  font-size: 24px;
}

.boxAlert {
  display: block;
  background-color: #edf1f6;
  padding: 20px;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  .boxAlert .glyphicon {
    font-size: 26px;
  }

}

.formWCA {
  margin: 0;

  .form-group .help-block.form-error {
    color: #ff0000;
  }

  fieldset {

    p {
      margin-top: 0;
    }

    ul {
      list-style: disc;
    }

    .boxRodoDesc {
      p {
        margin: 0;
      }
    }

  }
}